/* General App Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/src/BG.png') no-repeat center center fixed;
  background-size: cover;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
}

a {
  color: #61dafb;
  text-decoration: none;
  display: inline-block;
  margin: 10px;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

/* Header Section */
.header-container img {
  width: 100%;
  height: auto;
  display: block;
}

/* Content Section */
.content-container {
  margin: 50px auto;
  padding: 20px;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.1); /* Transparent background for contrast */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.blur-box {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  color: white;
  margin: 20px auto;
  max-width: 500px;
  text-align: center;
}

.blur-box h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #fff;
}

.blur-box p {
  font-size: 16px;
  color: #ddd;
  margin-bottom: 20px;
}

/* Buttons */
button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

button.connect {
  background: #f44336;
  color: white;
}

button.mint {
  background: #4CAF50;
  color: white;
}

.spaced-paragraph {
  line-height: 1.8; /* Adjust the value for more or less spacing */
}


button.logout {
  background: black;
  color: white;
}

/* Input Field */
input[type="password"] {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  outline: none;
  transition: border-color 0.3s ease;
}

input[type="password"]:focus {
  border-color: #4CAF50;
}

/* Image Links Section */
.image-links {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;

  border-radius: 8px;
}

.image-links a img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-links a img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
